import { Col, Container, Image, Row } from 'react-bootstrap'
import Link from 'next/link'
import dynamic from 'next/dynamic'

const Header 			= dynamic(() => import('../layout/common/header'));
const Troofooter 	= dynamic(() => import('../layout/common/troofooter'));

const Custom404 = () => {
  return (
    <>
      <Header />
      <div className="middle-sction middle-pad">
        <div className="page-404-middle">
          <section className="page-404-inner">
            <Container>
              <div className="page-404-content">
                <Row>
                  <Col lg={6}>
                    <div className="error-page-title">
                      <h1>
                        <span>Opps...!</span>
                        Page <u>Not Found</u>
                      </h1>
                    </div>
                    <div className="error-page-content">
                      <p>
                        Lost in cyberspace? You&apos;re not alone. It seems that
                        the page you were hoping to find has taken a detour. But
                        don&apos;t despair, we&apos;re here to guide you back on
                        track. Take a deep breath and let&apos;s explore
                        together. Who knows, this may be the start of a whole
                        new adventure!
                      </p>
                      <Link className="btn btn-primary" href="/">
                        Back to Home
                      </Link>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="error-page-img">
                      <Image src="/images/404-img.svg" alt="404" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </div>
      <Troofooter />
    </>
  )
}

export default Custom404
